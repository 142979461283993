import {
	$dqs,
	getCarouselEmbedEle,
	getSettingsStyle,
	getStars,
	getSvg,
	v,
} from "../swiper-reviews";
export async function setTextCarousel(settings, index) {
	if ($dqs('#tt-carousel[data-type="text"]')) {
		return;
	}

	const { swiperStyle, lang } = v;
	// let revRed = await v.request("getReviews", { has_resource: 1 });
	const column = v.columns[index];
	const target = getCarouselEmbedEle(
		"#trustoo-text-carousel",
		settings.is_home_active,
		settings.is_product_active,
		v.otherPageInsert.text
	);

	if (!target) {
		return;
	}

	const activationVal = 1,
		all = 1,
		feature = 2,
		productReviews = 5,
		reviewPhoto = 1,
		productPhoto = 2;
	const params = { sort_by: "commented-at-descending" };
	if (settings.show_reviews === all) {
		params.is_show_all = activationVal;
	} else if (settings.show_reviews === feature) {
		params.is_feature = activationVal;
	} else if (settings.show_reviews === productReviews) {
		if (v.productId) {
			params.product_id = v.productId;
		} else {
			params.is_show_all = activationVal;
		}
	}
	// if (settings.show_photos_option === productPhoto) {
	// 	params.is_show_corresponding_product = activationVal;
	// }
	const revRed = await v.request("getReviews", params);
	let reviewsList = null,
		ratingData = null;
	if (revRed[0]) {
		return;
	} else {
		if (revRed[1].list.length === 0) {
			return;
		}
		reviewsList = revRed[1].list;
		ratingData = revRed[1].total_rating;
	}
	v.reviewsLists[index] = reviewsList;
	const cardCount = reviewsList.length;
	let reviewsHtml = "";
	const s = settings;
	swiperStyle.textContent += `
		#tt-carousel[data-type=text]{
			--btn-bg-color: ${s.scroll_bg_color};
			--title-color: ${s.title_color};
			--desc-color: ${s.description_color};
			--block-bg-color:${s.block_bg_color};
			--card-bg-color:
			${settings.is_show_card == 1 ? settings.widget_layout_card_bg_color : "none"};
		--card-shadow:
			${
				settings.card_shadows_option == 1 && settings.is_show_card == 1
					? "0px 2px 10px 0px rgba(0, 0, 0, 0.10);"
					: "none"
			};
			--card-radius:${s.card_border_radius}px;
			--photo-radius: ${settings.image_border_radius + "px"};
		}
		.tt-swiper .tt-swiper-header .vstar-star{
			--header-star-color:${s.header_star_color};
			--header-nostar-color:${s.header_disabled_star_color};
		}
		.tt-swiper-ring{
			--scroll-color:${s.scroll_buttons_icon_color}
		}
		${getSettingsStyle(s, "text")}
		`;
	const imagePosition = ["above", "below"][settings.photos_position - 1];
	// const btnColor = settings.scroll_buttons_icon_color;
	reviewsList.forEach((it, inx) => {
		let verifiedHtml = "";
		if (settings.is_show_verified_purchase === 1 && it.verified_badge === 1) {
			verifiedHtml = `<div class="card-head-row2">
      ${v.badgeIconSvg}</div>`;
		}
		let imagesHtml = "";
		if (settings.is_show_photos === 1) {
			let imgSrc = "";
			if (it.resources.length) {
				const photoOption = settings.show_photos_option;
				if (photoOption === reviewPhoto) {
					const resource = it.resources[0];
					const reviewImageSrc =
						resource.resource_type === 2 ? resource.thumb_src : resource.src;
					imgSrc = reviewImageSrc || it.product_image_src;
				} else if (photoOption === productPhoto) {
					imgSrc = it.product_image_src;
				}
			} else {
				imgSrc = it.product_image_src;
			}
			let imageItem = "";
			if (imgSrc) {
				imageItem = `<img index="${inx}"  alt="${inx}"
				data-original="${imgSrc}" src="${imgSrc}" />`;
			} else {
				imageItem = getSvg("noImage");
			}
			imagesHtml = `<div class="resource-list"><div class="resource-item tt-reviews-image" >
				${imageItem}
			</div></div>`;
		}

		reviewsHtml += `
		<div class="swiper-slide">
    <div class="tt-card-wrapper"> 
		<div class="tt-card" no="${inx}"> 
		<div> ${getSvg("comma", settings.widget_layout_text_color)} </div>
		${imagePosition === "above" ? imagesHtml : ""}
		<div class="tt-card-review"> ${it.content} </div>
		 <div class="tt-card-rating"> <div class="vstar-star${
				v.isSpecialRatingIcon ? " special" : " normal"
			}" style="justify-content: center;">${getStars(it.star, v)}</div> </div>
			${imagePosition === "below" ? imagesHtml : ""}
			 <div> <div class="tt-card-author"> <div>${it.author}</div> ${
			it.verified_badge === 1 ? verifiedHtml : ""
		} </div>  </div>  </div> </div></div>
    `;
	});
	let descHtml = "";
	if (settings.is_show_description === 1) {
		const numberContent = `<span id="tt-reviews-num" ${
			settings.click_to_view_option === 1 ? "data-total-reviews" : ""
		}>${ratingData.total_reviews}</span>`;

		const descText = lang.card_carousel.text.desc
			.replace("{{total_rating}}", ratingData.rating)
			.replace("{{total_reviews}}", numberContent);
		descHtml = `<div id="tt-carousel-desc">${descText}</div>`;
	}
	target.insertAdjacentHTML(
		"beforeend",
		`<div id="tt-carousel" data-type="text" class="tt-swiper column-${column} pending">
    <div class="tt-swiper-wrapper">
    <div id="tt-carousel-content">
		${
			settings.is_show_title === 1
				? `<div id="tt-carousel-title">${lang.card_carousel.text.title}</div>`
				: ""
		}
		${descHtml}
      <div id="tt-carousel-list" class="tt-card-list">
			<div class="tt-swiper-container swiper-wrapper swiper"
			class="tt-swiper-container"
			data-index="${index}"
			data-count="${cardCount}"
			navigation="false"
			space-between="20"
			loop="true"
			slides-per-view="${column}"
			${v.isSetTimer ? `autoplay autoplay-pause-on-mouse-enter="true"` : ""}
			speed="300"
		>
		${reviewsHtml}
		</div>
      
      </div>
			<div id="tt-swiper-ring-wrapper">
			<span class="tt-swiper-ring active tt-swiper-pre"></span>
			${
				cardCount > v.columns[index]
					? `<span class="tt-swiper-ring middle"></span> <span class="tt-swiper-ring tt-swiper-next"></span>`
					: ""
			}
			</div>
    </div>
  </div>
    </div>
  `
	);
}
