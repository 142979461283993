import {
	$dqs,
	$dqsa,
	getCarouselEmbedEle,
	getSettingsStyle,
	getStars,
	getSvg,
	v,
} from "../swiper-reviews";

const cardWidth = 452;
const gap = 32;

export async function setBarrageCarousel(settings, index) {
	if ($dqs('#tt-carousel[data-type="barrage"]')) {
		return;
	}
	const { swiperStyle, lang } = v;
	const column = v.columns[index];

	const target = getCarouselEmbedEle(
		"#trustoo-barrage-carousel",
		settings.is_home_active,
		settings.is_product_active,
		v.otherPageInsert.barrage
	);
	if (!target) {
		return;
	}
	v.barrageSpeed = settings.speed * 1000;
	const activationVal = 1,
		all = 1,
		feature = 2,
		productReviews = 5;
	const params = { sort_by: "commented-at-descending" };
	if (settings.show_reviews === all) {
		params.is_show_all = activationVal;
	} else if (settings.show_reviews === feature) {
		params.is_feature = activationVal;
	} else if (settings.show_reviews === productReviews) {
		if (v.productId) {
			params.product_id = v.productId;
		} else {
			params.is_show_all = activationVal;
		}
	}
	const revRed = await v.request("getReviews", params);
	let reviewsList = null,
		ratingData = null;
	if (revRed[0]) {
		return;
	} else {
		if (revRed[1].list.length === 0) {
			return;
		}
		reviewsList = revRed[1].list;
		ratingData = revRed[1].total_rating;
	}
	v.reviewsLists[index] = reviewsList;
	let firstRowHtml = "",
		secondRowHtml = "";
	const s = settings;
	swiperStyle.textContent += `
	#tt-carousel{
		--title-color: ${s.title_color};
		--desc-color: ${s.description_color};
		--block-bg-color:${s.block_bg_color};
		--card-bg-color:${settings.widget_layout_card_bg_color};
		--card-radius:${s.card_border_radius}px;
		--photo-radius: ${settings.image_border_radius + "px"};
	}
	${getSettingsStyle(s, "barrage")}
	`;
	const cardCount = reviewsList.length;
	let firstRowReviews = null,
		secondRowReviews = null;
	const lineBreakCount = v.isMobile ? 5 : 10;
	let rowType = "first";

	if (cardCount > lineBreakCount) {
		firstRowReviews = reviewsList.filter((_, index) => index % 2 === 0);
		secondRowReviews = reviewsList.filter((_, index) => index % 2 !== 0);
	} else {
		firstRowReviews = reviewsList;
	}

	if (secondRowReviews) {
		secondRowHtml = getReviewListHtml(secondRowReviews, settings, "second");
	} else {
		rowType = "single";
	}
	firstRowHtml = getReviewListHtml(firstRowReviews, settings, rowType);
	let descHtml = "";
	if (settings.is_show_description === 1) {
		const numberContent = `<span id="tt-reviews-num" ${
			settings.click_to_view_option === 1 ? "data-total-reviews" : ""
		}>${ratingData.total_reviews}</span>`;

		const descText = lang.card_carousel.barrage.desc
			.replace("{{total_rating}}", ratingData.rating)
			.replace("{{total_reviews}}", numberContent);
		descHtml = `<div id="tt-carousel-desc">${descText}</div>`;
	}

	target.insertAdjacentHTML(
		"beforeend",
		`<div id="tt-carousel" data-type="barrage" class="tt-swiper column-${column} pending">
    <div class="tt-swiper-wrapper">
    <div id="tt-carousel-content">
		${
			settings.is_show_title === 1
				? `<div id="tt-carousel-title">${lang.card_carousel.barrage.title}</div>`
				: ""
		}
		${descHtml}
      <div id="tt-carousel-list" class="tt-card-list">
				<div class="tt-carousel-row">
					<div class="tt-swiper-container swiper-wrapper" data-index="${index}" data-count="${cardCount}" >
						${firstRowHtml}
					</div>
				</div>
				${
					secondRowHtml
						? `<div style="" class="tt-carousel-row carousel-second-row"><div class="tt-swiper-container swiper-wrapper" data-index="${index}" data-count="${cardCount}" >${secondRowHtml}</div></div>`
						: ""
				}
				
      </div>
			
    </div>
  </div>
    </div>
  `
	);

	const wrapper = $dqs('#tt-carousel[data-type="barrage"] #tt-carousel-list');
	const wrapperWidth = parseInt(getComputedStyle(wrapper).width);
	if (v.isMobile) {
		if (cardCount <= 2) {
			v.columns[index] = 1;
		} else {
			v.columns[index] = 2;
		}
	} else {
		if (cardCount < 5 && cardCount >= 3) {
			v.columns[index] = cardCount - 1;
		} else if (cardCount < 3) {
			v.columns[index] = cardCount;
		}
	}

	const gapCount = v.columns[index] - 1;

	const swiperWidth = v.columns[index] * cardWidth + gap * gapCount;

	if (swiperWidth < wrapperWidth) {
		wrapper.style.width = swiperWidth + "px";
		wrapper.style.margin = "0 auto";
	}

	if (v.isMobile && cardCount === 1) {
		$dqs(".tt-carousel-row", wrapper).style.width = $dqs(
			".tt-card",
			wrapper
		).style.width = "100%";
	} else {
		$dqsa(".tt-carousel-row", wrapper).forEach(
			it => (it.style.width = swiperWidth + "px")
		);
	}
}

function getReviewListHtml(reviews, settings, rowType) {
	const reviewPhoto = 1,
		productPhoto = 2;
	return reviews.reduce((html, it, inx) => {
		let imagesHtml = "";
		let imgSrc = "";
		if (it.resources.length) {
			const photoOption = settings.show_photos_option;
			if (photoOption === reviewPhoto) {
				const resource = it.resources[0];
				const reviewImageSrc =
					resource.resource_type === 2 ? resource.thumb_src : resource.src;
				imgSrc = reviewImageSrc || it.product_image_src;
			} else if (photoOption === productPhoto) {
				imgSrc = it.product_image_src;
			}
		} else {
			imgSrc = it.product_image_src;
		}
		let imageItem = "";
		let cardNo = inx;
		if (rowType !== "single") {
			cardNo = inx * 2;
			if (rowType === "second") {
				cardNo += 1;
			}
		}

		if (imgSrc) {
			imageItem = `<img index="0"  alt="review image"
			data-original="${imgSrc}" src="${imgSrc}" />`;
		} else {
			imageItem = getSvg("noImage");
		}
		imagesHtml += `<div class="resource-item tt-reviews-image" >
				${imageItem}
				</div>`;

		imagesHtml = `<div class="resource-list">${imagesHtml}</div>`;

		const reviewHtml = `
		<div class="swiper-slide">
    <div class="tt-card-wrapper"> 
		<div class="tt-card" no="${cardNo}">
		${imagesHtml} <div class="tt-card-right">
		 <div class="tt-card-head">
		  <div class="tt-card-author">${it.author}</div>
			 <div class="vstar-star${
					v.isSpecialRatingIcon ? " special" : " normal"
				}">${getStars(it.star, v)}</div>
		</div>
		 <div class="tt-card-review"> ${it.content}  </div> </div> </div> </div>
		 </div>
    `;
		html += reviewHtml;
		return html;
	}, "");
}
